import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import '../assets/styles/index.css'
import GlobalStyles from '../styles/GlobalStyles'

const StyledContent = styled.main`
  margin: 0 auto;
`

const Layout = ({ className, children }) => (
  <div className={className}>
    <GlobalStyles />
    <StyledContent>{children}</StyledContent>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Layout
